/**
 * Source of coaching sessions
 * Mostly in parity with the Python constants.py file
 */
export const CHATSOURCE = {
    HOME_DEFAULT: "home_default",
    HOME_SUGGESTED_TOPICS: "home_suggested_topics",
    HOME_ACTION_ITEMS: "home_action_items",
    HOME_INSIGHTS: "home_insights",
    ONBOARDING_DEEP_DIVE: "onboarding_deep_dive",
    NEW_USER_CHECKLIST: "new_user_checklist",
    HOME_CHAT_MESSAGE: "home_chat_message",
    SKILL_BUILDING: "skill_building",
    PENDING_ACTION_ITEMS: "pending_action_items",
    TRENDING_ORG_PROMPT: "trending_org_prompt",
    HOME_WRAPPED: "home_wrapped",
    LEFT_SIDEBAR: "left_sidebar",
    PAST_CONVERSATIONS: "past_conversations",
};
